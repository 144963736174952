.tl-header {
  position: fixed;
  position: sticky;
  z-index: 555;
  left: 0;
  right: 0;
  top: 0;
  height: $header-height-mob;
  background-color: $cl-bg-1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @media (min-width: $w-brkpnt-md) {
    height: $header-height-desk;
  }
  ul, li {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
  }

  &--row {
    @include flex-align-center;
  }
  &--left {
		@media (min-width: $w-brkpnt-md) {
			width: $w-side-col-left;
		}
	}
	&--center {
		@media (min-width: $w-brkpnt-md) {
      width: $w-side-center;
      max-width: calc(100% - #{$w-side-col-left});
      padding-left: 32px;
      padding-right: 32px;
    }
    @media (min-width: $w-brkpnt-lg) {
      width: calc(100% - #{$w-side-col-left});
      max-width: calc(100% - #{$w-side-col-left});
    }
	}
	&--right {
		@media (min-width: $w-brkpnt-lg) {
      width: $w-side-right;
		}
	}
  &--search {
    @media (min-width: $w-brkpnt-md) {
      width: 260px;
    }
    @media (min-width: $w-brkpnt-lg) {
      width: 560px;
    }
  }
  &--dropdown {
    margin-top: 8px;
  }
  &--logo {
    line-height: 1;
    text-decoration: none;
    &-text {
      @include title-font;
      text-transform: lowercase;
      font-size: 28px;
      line-height: 1;
    }
    img {
      max-height: $input-height-md;
      max-width: 100%;
      width: auto;
      vertical-align: top;
      @media (min-width: $w-brkpnt-md) {
        max-height: $input-height-xl;
      }
    }
  }
}

.search-box {
  @include trn-03;
  position: absolute;
  z-index: 777;
  transform: translate(-50%, 16px);
  opacity: 0;
  visibility: hidden;
  top: $header-height-mob;
  left: 50%;
  width: 100%;
  background-color: $cl-bg-1;
  margin-top: -1px;
  border-top: 1px solid $cl-outline;
  border-bottom: 1px solid $cl-outline;
  &--backdrop {
    @include backdrop;
    z-index: 666;
    top: $header-height-mob;
    @media (min-width: $w-brkpnt-md) {
      top: $header-height-desk;
    }
  }
  @media (min-width: $w-brkpnt-sm) {
    max-width: 520px;
    box-shadow: $shadow-3;
    border-radius: 0 0 $bord-rad-1 $bord-rad-1;
    margin-top: 0;
    border-bottom: none;
  }
  @media (min-width: $w-brkpnt-md) {
    top: $header-height-desk;
  }
}

.tl-searchform {
  &--row {
    position: relative;
  }
  &--field {
    padding: 1rem;
  }
  &--btn {
    padding: 1rem;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.tl-header {
  &.search-open {
    .search-box {
      transform: translate(-50%, 0);
      opacity: 1;
      visibility: visible;
      &--backdrop {
        display: block;
      }
    }
  }
}

.header-user {
  img {
    box-shadow: $shadow-6;
    width: $input-height-md;
    height: auto;
    @media (min-width: $w-brkpnt-md) {
      width: $input-height-xl;
    }
  }
  @media (min-width: $w-brkpnt-sm) {
    max-width: 125px;
    span {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.header-btn {
  @media (max-width: calc(#{$w-brkpnt-md} - 1px)) {
    min-height: $input-height-md !important;
    min-width: $input-height-md !important;
    padding: 7px !important;
  }
}

.tl-topbar {
  width: 100%;
  // height: $header-height-mob;
  @media (min-width: $w-brkpnt-md) {
    height: $header-height-desk;
  }
  &--message {
    @include txt-ttl-3;
    text-align: center;
    @media (min-width: $w-brkpnt-sm) {
      @include txt-ttl-4;
    }
    p {
      margin: 0;
    }
    &-btn {
      width: 100%;
      @media (min-width: $w-brkpnt-sm) {
        width: auto;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
      }
    }
  }
}

body {
  &.logged-in {
    .tl-header {
      position: fixed;
    }
  }
}