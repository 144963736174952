.tl-comments {
  .wpd-prim-button,
  .wpd-second-button {
    @include trn-03;
    @include btn-reset;
    @include btn-align;
    @include txt-btn;
    @include btn-xl;
    border-radius: calc(#{$input-height-xl / 2});
  }
  .wpd-prim-button {
    @include btn-acc-1;
  }
  .wpd-second-button {
    @include btn-sec;
  }
  .comments-area {
    padding: 0;
  }
  .wpd-sbs-toggle {
    color: $cl-main-opaque;
  }
  #comments {
    margin: 0;
    color: $cl-main;
  }
  #wpdcom {
    margin: 0;
    padding: 0;
    .wpd-thread-head {
      border: none;
    }
    .wpd-form-wrap {
      .wpd-form-head {
        border-color: $cl-outline;
      }
    }
    .wpd-main-form-wrapper {
      margin-top: 32px;
    }
    .wpd-secondary-form-wrapper {
      margin-top: 16px;
    }
    .wpd-thread-filter {
      margin-bottom: -1px;
      .wpd-filter {
        @include txt-btn;
        @include flex-align-center;
        border-bottom: 2px solid $cl-main;
        color: $cl-main;
        padding: 8px 0;
        margin: 0;
      }
    }
    .wpd-tools-actions,
    .wpdiscuz-sort-buttons {
      @include txt-body-1;
      top: 100%;
      padding: 0;
      color: $cl-main;
      text-align: left;
      min-width: $dropdown-min-width;
      background-color: $dropdown-bg;
      border: none;
      border-radius: $bord-rad-2;
      box-shadow: $shadow-3;
      .wpd-cta-button,
      .wpdiscuz-sort-button {
        padding: 16px;
      }
    }
    .wpdiscuz-subscribe-bar {
      #wpdiscuz-subscribe-form {
        @include flex-align-center;
        padding-top: 8px;
        .wpdiscuz-subscribe-form-intro {
          @include txt-body-2;
          width: 60px;
        }
        .wpdiscuz-subscribe-form-option {
          padding-left: 8px;
          padding-right: 8px;
          flex: 1;
        }
        .wpdiscuz-subscribe-form-button {
          width: 40px;
        }
      }
    }
    .wpd_comm_form,
    .wpdiscuz-edit-form-wrap {
      border: 1px solid $cl-outline;
      padding: 16px;
    }
    .wpdiscuz-edit-form-wrap {
      margin-top: 16px;
      textarea {
        @include txt-body-1;
        width: 100%;
        border: none;
        padding: 0;
        resize: none;
        &:focus {
          outline: none;
        }
      }
      .wc_save_wrap {
        @include flex-align-center;
        justify-content: flex-end;
        input {
          + input {
            margin-left: 16px;
          }
        }
      }
    }
    .wpd_comm_form {
      .wpdiscuz-textarea-wrap {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        .wpd-avatar {
          display: inline-block;
          width: 40px;
          img {
            width: 40px;
            height: auto;
            border-radius: $bord-rad-2;
          }
        }
        .wpd-field {
          @include txt-body-1;
          padding: 8px 16px;
          flex: 1;
          border: none;
          resize: none;
          &:focus {
            outline: none;
          }
        }
      }
      .wpd-form-foot {
        .wpd-form-col-left {
          display: none;
        }
      }
      .wc-field-submit {
        @include flex-align-center;
        justify-content: flex-end;
        padding: 0;
        .wpd_label {
          display: none;
        }
      }
    }
    .wpd-thread-wrapper {
      margin-top: 32px !important;
    }
    .wpd-thread-list {
      .wpd-comment {
        + .wpd-comment {
          margin-top: 32px;
        }
        .wpd-comment.wpd-reply {
          margin-top: 32px;
        }
      }
    }
    .wpd-comment-last-edited {
      @include txt-caption;
      color: $cl-main-opaque;
      margin-top: -8px;
      .fa-edit {
        display: none;
      }
    }
    [id^=wpd-editor-wraper] {
      flex: 1;
      border: none;
      .wpd-editor-char-counter,
      .ql-toolbar {
        display: none;
      }
      .ql-container {
        overflow: hidden;
        .ql-texteditor {
          textarea {
            display: inline !important;
            opacity: 0;
            position: absolute;
            left: -10000px;
          }
        }
      }
      .ql-container.ql-snow {
        border: none;
      }
      .ql-editor {
        @include txt-body-1;
        padding: 8px 16px;
        &.ql-blank::before {
          font-style: normal;
        }
      }
    }
  }
}

#wpdiscuz-comment-message {
  .wpdiscuz-message-success {
    background-color: $cl-acc-2;
  }
  .wpdiscuz-message-warning {
    background-color: $cl-acc-4;
  }
  .wpdiscuz-message-error {
    background-color: $cl-error;
  }
  .wpdiscuz-message-success,
  .wpdiscuz-message-warning,
  .wpdiscuz-message-error {
    padding: 8px;
    box-shadow: $shadow-3;
    border-radius: $bord-rad-2;
  }
}

.fa-icon {
  font-size: 16px;
}

.tl-comment {
  &--top {
    display: flex;
    flex-wrap: wrap;
  }
  &--avatar {
    width: 40px;
    a {
      display: inline-block;
      width: 100%;
    }
    img {
      border-radius: $bord-rad-2;
      width: 100%;
      height: auto;
    }
  }
  &--link {
    position: relative;
    cursor: pointer;
    span {
      @include absolute-parent-size;
      z-index: 1;
      opacity: 0;
      i {
        @include absolute-parent-size;
        z-index: 1;
        opacity: 0;
      }
    }
  }
  &--footer {
    @include flex-align-center;
  }
  &--vote {
    .vote-btn {
      cursor: pointer;
    }
    .wpd-vote-result {
      @include flex-align-justify-center;
      @include txt-subttl-2;
      width: 32px;
      height: 32px;
      border-radius: 100%;
      background-color: $cl-surface;
      color: $cl-main-opaque;
      &.wpd-up {
        color: $cl-acc-5;
        background-color: rgba($cl-acc-5, 0.1);
      }
      &.wpd-down {
        color: $cl-error;
        background-color: rgba($cl-error, 0.1);
      }
    }
  }
}

.item-comment--head-ttl {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}