/* -----------------------------------------------------
  Material Design Sliders
  CodePen URL: https://codepen.io/rkchauhan/pen/xVGGpR
  By: Ravikumar Chauhan
  
  Find me on:-
  * Twitter: https://twitter.com/rkchauhan01
  * Facebook: https://www.facebook.com/ravi032chauhan
  * GitHub: https://github.com/rkchauhan
  * CodePen: https://codepen.io/rkchauhan
  * UpLabs: http://uplabs.com/rkchauhan01

  Thanks to:-
  * Google Material design - https://www.google.com/design/spec/material-design/introduction.html
  * Google Material Color - https://www.google.com/design/spec/style/color.html
  * Google Material Icons - https://design.google.com/icons/
  * Roboto Font - https://google.com/fonts/specimen/Roboto
  * jQuery - https://jquery.com
-------------------------------------------------------- */

.tl-range {
  display: block;
  position: relative;

  input[type="range"] {
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;

    & + .slider {
      display: block;
      position: relative;
      width: 100%;
      height: 4px;
      background-color: $cl-acc-1-muted;

      .slider-fill {
        display: block;
        position: absolute;
        width: 0%;
        height: 100%;
        user-select: none;
        z-index: 1;
      }

      .slider-handle {
        cursor: pointer;
        position: absolute;
        top: -5.5px;
        left: 0%;
        width: 15px;
        height: 15px;
        margin-left: -8px;
        border-radius: 50%;
        transition: all .2s ease;
        user-select: none;
        z-index: 2;
      }
    }

    &:disabled + .slider {
      opacity: 0.5;

      .slider-fill,
      .slider-handle {
        cursor: default !important;
        .slider-label {
          display: none; 
        }

        &.is-active {
          top: -5.5px;
          width: 15px;
          height: 15px;
          margin-left: -8px;

          .slider-label {
            display: none;
            border-radius: 50%;
            transform: none;
          }
        }
      }

      .slider-handle:active {
        box-shadow: none !important;
        transform: scale(1) !important;
      }
    }
  }

  .slider-handle:active { 
    transform: scale(1.4); 
  }

  .slider-fill,
  .slider-handle {
    background-color: $cl-acc-1;
  }
}