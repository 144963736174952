.tl-footer {
  @include txt-caption;
  box-shadow: $shadow-2;
  background-color: $cl-light;
  z-index: 777;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: $footer-height;
  padding: 7px 0 5px;
  &--container {
    @media (min-width: $w-brkpnt-md) {
      max-width: $w-brkpnt-md;
      margin-left: auto;
      margin: auto;
    }
    @media (min-width: $w-brkpnt-lg) {
      max-width: $w-brkpnt-lg;
      margin-left: auto;
      margin: auto;
    }
  }
  a {
    color: $cl-main-muted;
    &:hover {
      color: $cl-main;
    }
  }
  .soc-icon {
    color: $cl-main-muted;
  }
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.footer-nav {
  @include txt-caption;
  @include flex-align-center;
  li {
    a {
      @include txt-caption;
    }
    &:after {
      content: ' ・ ';
    }
    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}