* {
	-webkit-font-smoothing: antialiased
}

html {
	font-size: 16px;
	scroll-behavior: smooth;
}

body {
	@include txt-body-2;
	position: relative;
	color: $cl-main;
	background-color: $cl-light;
	overflow-x: hidden;
	min-height: 100vh;
	max-width: 100%;
	display: flex;
	flex-direction: column;
	&.touch-device {
		.d-touch-none {
			display: none !important;
		}		
		.tl-header {
			max-width: 100vw;	
		}
	}
}

::-webkit-scrollbar {
	width: 0.5rem;
	height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: $cl-main-opaque;
	border-radius: 0.25rem;
}

::selection {
  color: $cl-light;
  background: $cl-acc-1;
} 

[role="button"] {
	cursor: pointer;
}

a {
	@include trn-03;
	color: $cl-main;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}

img {
	&.bord-rad-100 {
		border-radius: 100% !important; 
	}
}

.link-underline {
	cursor: pointer;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
}

.link-underline-hover {
	cursor: pointer;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
}

.position-relative {
	position: relative;
}

.tl-sep {
	height: 16px;
	width: 100%;
	background-color: $cl-surface;
}

.container-auth,
.container-cont,
.container-xl,
.container-sm,
.container-xs,
.container-xxs {
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.container-xl {
	@media (min-width: $w-brkpnt-lg) {
		max-width: $w-brkpnt-lg;
	}
}

.container-sm {
	padding-left: 16px;
	padding-right: 16px;
	max-width: 688px;
	.container-row-gutter {
		margin-left: -16px;
		margin-right: -16px;
	}
	&.mod-mob-pad {
		padding-left: 0;
		padding-right: 0;
		@media (min-width: $w-brkpnt-sm) {
			padding-left: 32px;
			padding-right: 32px;
		}
	}
	@media (min-width: $w-brkpnt-sm) {
		padding-left: 32px;
		padding-right: 32px;
		.container-row-gutter {
			margin-left: -32px;
			margin-right: -32px;
		}
	}
}

.container-auth {
	padding-left: 24px;
	padding-right: 24px;
	max-width: 928px;
}

.container-cont {
	padding-left: 24px;
	padding-right: 24px;
	@media (min-width: $w-brkpnt-sm) {
		padding-left: 32px;
		padding-right: 32px;
	}
}

.container-xs {
	max-width: 432px;
}

.container-xxs {
	max-width: 320px;
}

.min-w-100 {
	min-width: 100%;
}

body {
  &.logged-in {
    .tl-main {
      padding-top: $header-height-mob;
			@media (min-width: $w-brkpnt-md) {
				padding-top: $header-height-desk;
			}
    }
  }
}

.tl-main {
	@media (min-width: $w-brkpnt-md) {
		display: flex;
	}
	&--left {
		@media (min-width: $w-brkpnt-md) {
			width: 272px;
		}
	}
	&--right {
		@media (min-width: $w-brkpnt-md) {
			order: 3;
		}
	}
	&--center {
		padding-bottom: 24px;
		@media (min-width: $w-brkpnt-sm) {
			max-width: 624px;
			margin-left: auto;
			margin-right: auto;
			border-left: 1px solid $cl-outline;
			border-right: 1px solid $cl-outline;
		}
		@media (min-width: $w-brkpnt-md) {
			margin-left: 0;
			margin-right: 0;
			width: 624px;
			// padding-bottom: calc(#{$footer-height} * 3);
		}
	}
}

.tl-listing {
	@media (min-width: $w-brkpnt-sm) {
		padding-left: 0;
		padding-right: 0;
	}
	&.mod-posts-home {
		.feed-item {
			&:first-child {
				.tile-post {
					.tl-sep {
						display: none;
					}
					.tile-post--head {
						border-top: 1px solid $cl-outline;
					}
				}
			}
		}
	}
	&.no-loader {
		.feed-loader-wrap {
			display: none;
		}
	}
	.lstng {
		.feed-item {
			&:first-child {
				.tile-post {
					.tile-post--head {
						border-top: 1px solid $cl-outline;
					}
				}
			}
		}
	}
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-help {
	cursor: help;
}

.overflow-slider {
	overflow: visible;
}

.flex-1 {
	flex: 1;
}

.flex-align-center {
	@include flex-align-center;
}

.flex-justify-center {
	@include flex-justify-center;
}

.flex-align-justify-center {
	@include flex-align-justify-center;
}

.relative-index-10 {
	@include relative-index-10;
}

.relative-index-20 {
	@include relative-index-20;
}

.list-reset {
	@include list-reset;
}

.anchor-el {
	&--target {
		padding: 0;
		margin: 0;
		position: relative;
		height: 0;
		z-index: -1;
		top: calc(#{-$header-height-mob} - 24px);
		@media (min-width: $w-brkpnt-sm) {
			top: calc(#{-$header-height-desk} - 24px);
		}
	}
}

.bg-cover {
	@include bg-cover;
}

.img-cover {
	position: relative;
	overflow: hidden;
	> img {
		position: absolute;
		height: 100%;
		width: 100%;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		object-fit: cover;
	}
}

.bg-contain {
	@include bg-contain;
}

.sticky-sidebar {
	@media (min-width: $w-brkpnt-sm) {
		&.sticky-md {
			position: sticky;
			top: $header-height-mob;
		}
	}
}

.bord-rad-1 {
	border-radius: $bord-rad-1;
}

.bord-rad-2 {
	border-radius: $bord-rad-2;
}

.bord-rad-3 {
	border-radius: $bord-rad-3;
}

.bord-rad-100 {
	border-radius: 100%;
}

.tl-main--mob--nav-itemholder {
	display: inline-block;
	padding-top: 8px;
	padding-bottom: 8px;
}

.src-replace {
	background-color: $cl-surface;
	transition: all 0.1s linear 0.1s;
	opacity: 0;
	&.loaded {
		opacity: 1;
		background-color: transparent;
	}
}

// Admin bar tweaks
@media screen and (min-width: 783px) {
	body.admin-bar {
		.tl-header,
		.tl-main,
		.search-box {
			margin-top: 32px !important;
		}
	}
}