.cat-dropdown {
  min-width: calc(100vw - 2rem);
  @media (min-width: 576px) {
    min-width: 35rem;
  }
  @media (min-width: 1025px) {
    margin-top: 1.5rem;
    min-width: 200%;
  }
  @media (min-width: $w-brkpnt-md) {
    margin-top: 1rem;
    min-width: 73rem;
  }
  &--wrapper {
    position: relative;
  }
  &--parent {
    width: 100%;
    @media (min-width: 1025px) {
      width: 50%;
      padding-right: 5rem;
    }
  }
  &--child {
    width: 100%;
    @media (min-width: 1025px) {
      position: absolute;
      top: 0;
      width: 50%;
      right: 0;
      padding-left: 5rem;
    }
  }
  &--btn {
    @include btn-reset;
    @include txt-body-2;
    position: relative;
    padding: 1rem 3rem 1.5rem 0;
    text-align: left;
    @media (min-width: 1025px) {
      padding: 1rem 3rem 2rem 0;
    }
    @media (min-width: $w-brkpnt-md) {
      padding-right: 40%;
    }
    &:before {
      @include trn-03;
      content: '';
      position: absolute;
      left: 0;
      bottom: -0.2em;
      width: 100%;
      border-bottom: 1px solid $cl-main-opaque;
    }
    &:after {
      @include trn-03;
      @include bg-contain;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAxOSAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuNSAwLjVWMTkuNSIgc3Ryb2tlPSJibGFjayIvPgo8cGF0aCBkPSJNMTkgMTBMMCAxMCIgc3Ryb2tlPSJibGFjayIvPgo8L3N2Zz4K);
      content: '';
      position: absolute;
      right: 0;
      top: 1rem;
      height: 1.1em;
      width: 1.1em;
      @media (min-width: 1025px) {
        display: none;
      }
    }
    &[aria-expanded="true"],
    &:hover {
      &:before {
        border-width: 4px;
        border-color: $cl-main;
      }
    }
    &[aria-expanded="true"] {
      &:after {
        transform: rotate(135deg);
      }
      @media (min-width: 1025px) {
        pointer-events: none;
      }
    }
    &-count {
      position: absolute;
      right: 0;
      top: 1rem;
      line-height: 2;
    }
  }
  &--all {
    position: relative;
    padding-bottom: 1rem;
    @media (min-width: 1025px) {
      padding-right: 4rem;
    }
    @media (min-width: $w-brkpnt-md) {
      padding-bottom: 1.5rem;
    }
    &:after {
      content: "\2192";
      position: absolute;
      right: 0;
      top: 0;
      @media (min-width: 1025px) {
        content: attr(data-count);
        color: $cl-main-opaque;
      }
    }
  }
}