.hero {
  padding-top: 16px;
  padding-bottom: 16px;
  @media (min-width: $w-brkpnt-md) {
    background-color: $cl-light;
    padding-top: 32px;
    padding-bottom: 32px;
  }
  &-quicklinks {
    overflow: hidden;
    &--row {
      @include flex-align-center;
    }
  }
}

.hero-01 {
  &--ttl {
    @include txt-ttl-1;
  }
}