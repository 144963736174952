.editor-content {
  @include txt-body-1;

  h1, h2, h3 {
    @include txt-ttl-2;
  }

  h4, h5, h6 {
    @include txt-ttl-3;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 24px;
  }

  p, ul, ol, .content-slider {
    margin-bottom: 24px;
  }

  ul, ol, .content-slider {
    margin-top: 24px;
  }

  .has-small-font-size {
    @include txt-body-2;
  }

  .has-medium-font-size {
    @include txt-subttl-1;
  }

  img {
    max-width: 100%;
  }

  > img,
  > .wp-block-image,
  > .wp-block-embed-youtube,
  > .wp-block-embed-vimeo,
  > .content-slider {
    @include content-media;
  }

  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: 24px;
  }

  blockquote {
    padding-left: 24px;
    border-left: 2px solid $cl-main;
    margin-top: 32px;
    margin-bottom: 32px;
    p {
      @include txt-subttl-1;
      margin-bottom: 0;
    }
    cite {
      @include txt-body-2;
      display: inline-block;
      margin-top: 8px;
      color: $cl-main-opaque;
    }
  }

  figure {
    text-align: center;
    margin-top: 32px;
    margin-bottom: 32px;
    img {
      margin-left: auto;
      margin-right: auto;
    }
    figcaption {
      @include txt-body-2;
      color: $cl-main-opaque;
    }
  }

  iframe {
    border: none;
    max-width: 100%;
  }

  > * {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .wp-block-embed__wrapper {
    @include embed-video;
  }
}

.post-header {
  padding-bottom: 32px;
}
