.wpuf-page {
  .posts-form {
    &.mod-opaque {
      opacity: 0.7;
      .wpuf-submit {
        position: relative;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
          cursor: not-allowed;
        }
        .wpuf-submit-button {
          cursor: not-allowed;
        }
      }
    }
  }

  // $('.topic') element trick (to prevent submit if empty)
  .posts-form--topics {
    select {
      display: block !important;
      opacity: 0;
      position: absolute;
      left: -10000px;
    }
    .wpuf-fields {
      display: flex;
      flex-direction: column;
      .wpuf-error-msg {
        order: 12;
      }
    }
  }

  .posts-form {
    .wpuf-form {
      flex-wrap: wrap;
      flex-direction: row;
    }
    .wpuf-el {
      &.posts-form--topics {
        width: auto;
        max-width: 100%;
        margin-bottom: 8px;
        .wpuf-fields {
          display: flex;
          flex-direction: column;
          .selectize-control {
            order: -1;
          }
        }
      }
      &.post_title {
        input[type="text"] {
          @include txt-ttl-4;
          border-bottom: none;
          padding-top: 0;
        }
      }
      &.custom_html {
        flex: 0 0 60%;
        max-width: 60%;
        width: 60%;
        margin-top: 16px;
        margin-bottom: 0;
      }
      &.removableField {
        display: none;
        &.visible {
          display: block;
        }
        .wpuf-label {
          @include flex-align-center;
        }
      }
    }
    .wpuf-submit {
      flex: 0 0 40%;
      max-width: 40%;
      width: 40%;
      margin-bottom: 0;
      text-align: right;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .wpuf-errors {
        display: none; 
      }
      .wpuf-loading {
        order: -1;
        position: static;
        left: auto;
        top: auto;
        transform: none;
        margin-right: 16px;
      }
      .wpuf-submit-button {
        border-radius: calc(#{$input-height-xl} / 2);
        width: auto;
      }
    }
    &--trigger {
      color: $cl-main-opaque;
      &:hover,
      &.active {
        color: $cl-main;
      }
    }
  }  
  .posts-form--fetched {
    .wpuf-label {
      label {
        display: none;
      }
    }
    .wpuf-fields {
      display: none;
    }
  }
}

.fetched-img--wrap {
  width: 150px;
  height: 150px;
  max-width: 100%;
  border-radius: $bord-rad-2;
  border: 1px solid $cl-outline;
  position: relative;
}

.fetched-img--close {
  @include flex-align-justify-center;
  position: absolute;
  z-index: 10;
  right: 4px;
  top: 4px;
  font-size: 24px;
  line-height: 1;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  background-color: $cl-light-opaque;
  border: 1px solid transparent;
  color: $cl-main;
  cursor: pointer;
  text-decoration: none;
}