.btn-icon {
  @include trn-03;
  @include btn-reset;
  @include btn-align;
  border: 1px solid transparent;
  padding: 15px;
  min-width: $input-height-xl;
  min-height: $input-height-xl;
  border-radius: 100%;
  &.mod-sm {
    padding: 0;
    min-width: 24px;
    min-height: 24px;
    border-radius: 12px;
  }
  &.mod-md {
    padding: 0;
    min-width: $input-height-md;
    min-height: $input-height-md;
    border-radius: $input-height-md;
  }
  &.mod-light {
    &:hover,
    &:active {
      background-color: $cl-outline;
    }
  }
  &.mod-dark {
    color: $cl-light-muted;
    &:hover,
    &:active {
      background-color: $cl-light-opaque-extra;
    }
  }
}

.tl-btn {
  @include trn-03;
  @include btn-reset;
  @include btn-align;
  @include txt-btn;
  @include btn-def;
}

.btn-acc-1 {
  @include btn-acc-1;
}
.btn-acc-2 {
  @include btn-acc-2;
}
.btn-acc-6 {
  @include btn-acc-6;
}
.btn-sec {
  @include btn-sec;
}
.btn-flat {
  @include btn-flat;
}
.btn-outline {
  @include btn-outline;
}
.btn-sm {
  @include btn-sm;
  border-radius: $bord-rad-1;
}
.btn-md {
  @include btn-md;
  border-radius: $bord-rad-1;
}
.btn-lg {
  @include btn-lg;
  border-radius: $bord-rad-1;
}
.btn-xl {
  @include btn-xl;
  border-radius: $bord-rad-1;
}
.btn-ellipsis {
  @include btn-ellipsis;
}
.btn-shadow {
  box-shadow: $shadow-6;
  border-color: transparent;
}
.btn-rounded {
  border-radius: 100%;
}
.btn-icon-prepend {
  padding-left: 10px;
  .btn-icn {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.btn-icon-append {
  padding-right: 10px;
  .btn-icn {
    width: 24px;
    height: 24px;
    margin-left: 8px;
  }
}
.reset-shadow {
  box-shadow: none;
}

.btn-ellipsis {
  @include btn-ellipsis;
}