.tl-sidebar {
  width: $w-side-col-left;
  height: calc(100% - #{$header-height-mob});
  overflow: auto;
  position: fixed;
  top: $header-height-mob;
  background-color: $cl-light;
  z-index: 555;
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  &--header {
    padding: 32px 16px 8px 16px;
    @media (min-width: $w-brkpnt-lg) {
      padding-right: 32px;
    }
  }
  &--body {
    padding: 16px;
    @media (min-width: $w-brkpnt-lg) {
      padding-right: 32px;
    }
  }
  &--backdrop {
    @include backdrop;
    top: 0;
  }
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 0;
  }
  &.mod-open {
    z-index: 777;
    + .tl-sidebar--backdrop {
      display: block;
      z-index: 666;
    }
  }
  @media (min-width: $w-brkpnt-md) {
    height: calc(100vh - #{$header-height-desk});
    top: $header-height-desk;
    display: flex;
    flex-direction: column;
  }
  &.mod-left {
    left: 0;
    transform: translateX(-100%);
    border-right: 1px solid $cl-outline;
    &.mod-open {
      transform: translateX(0);
    }
    @media (min-width: $w-brkpnt-md) {
      z-index: 1;
      position: sticky;
      transform: translateX(0);
      border: none;
    }
  }
  &.mod-right {
    right: 0;
    transform: translateX(100%);
    width: $w-side-right;
    &.mod-open {
      transform: translateX(0);
    }
    @media (min-width: $w-brkpnt-lg) {
      z-index: 1;
      order: 3;
      position: sticky;
      transform: translateX(0);   
    }
  }
  &--footer {
    a {
      color: $cl-main-muted;
      &:hover {
        color: $cl-main;
      }
      svg {
        opacity: 0.6;
      }
    }
  }
}

.side-signup {
  background-color: $cl-outline-light;
  border-radius: $bord-rad-3;
  .tl-subscribe {
    &--input {
      @include txt-fld-size-md;
      margin-bottom: 8px;
      background-color: $cl-light;
    }
    &--submit {
      @include btn-sec;
      border-color: transparent;
    }
    &--input,
    &--submit {
      padding: 8px 24px;
      flex: 0 0 100%;
      min-height: $input-height-md;
      border-radius: calc(#{$input-height-md} / 2);
    }
  }
}

.sidebar-reviews {
  @media (min-width: $w-brkpnt-lg) {
    height: 100%;
  }
}

.side-subscribe {
  padding: 16px;
  @media (min-width: $w-brkpnt-lg) {
    padding-right: 32px;
    position: sticky;
    top: $header-height-desk;
  }
}