.embed-video {
  @include embed-video;
}

.tl-panel {
	padding: 24px;
  background-color: $cl-light;
  &.mod-outlined {
    border: 1px solid $cl-outline;
  }
}

.tile-team {
  @include trn-03;
  position: relative;
  border-top: 16px solid $cl-surface;
  @media (min-width: $w-brkpnt-sm) {
    border-top: 1px solid $cl-outline;
    &:hover {
      background-color: $cl-outline-light;
      .tile-team--site {
        color: $cl-acc-1;
      }
    }
    &.trigger-hovered {
      background-color: $cl-bg; 
    }
  }
  &--title {
    @include txt-ttl-4;
    a {
      &:hover {
        text-decoration: underline;
      }
    }
    @media (min-width: $w-brkpnt-sm) {
      @include txt-ttl-3;
    }
  }
  &--rating {
    @include txt-caption;
    @media (min-width: $w-brkpnt-sm) {
      @include txt-subttl-2;
    }
  }
  &--link {
    @include absolute-parent-size;
    z-index: 1;
  }
  &--top {
    padding: 16px;
    @media (min-width: $w-brkpnt-sm) {
      padding: 32px 0;
    }
  }
  &--inner {
    border-top: 1px solid $cl-outline;
    @media (min-width: $w-brkpnt-sm) {
    }
    &-left {
      width: 100%;
      @media (min-width: $w-brkpnt-sm) {
        width: 50%;
      }
    }
    &-right {
      width: 100%;
      position: relative;
      @media (min-width: $w-brkpnt-sm) {
        width: 50%;
        height: auto;
        border-left: 1px solid $cl-outline;
      }
    }
  }
  &--top,
  &--inner {
    display: flex;
    flex-wrap: wrap;
  }
  &--img {
    width: $input-height-xl;
    height: $input-height-xl;
    border-radius: 50%;
    .team-logo {
      width: $input-height-xl;
      height: $input-height-xl;
    }
  }
  &--excerpt {
    @include txt-body-2;
    padding-top: 8px;
    width: 100%;
    @media (min-width: $w-brkpnt-sm) {
      @include txt-body-2;
      padding-left: 16px;
      padding-right: 32px;
      padding-top: 0;
      width: calc(100% - #{$input-height-xl} - 90px);
      color: $cl-main-muted;
    }
  }
  &--votes {
    position: relative;
    width: 90px;
    height: $input-height-xl;
    margin-left: auto;
    @media (min-width: $w-brkpnt-sm) {
      order: 3;
    }
  }
  &--gallery {
    overflow: hidden;
  }
  &--info {
    padding: 16px;
    border-top: 1px solid $cl-outline;
    &-cap {
      width: 98px;
       @media (min-width: $w-brkpnt-sm) {
         display: none;
       }
    }
    &-text {
      width: calc(100% - 98px);
       @media (min-width: $w-brkpnt-sm) {
         width: 100%;
       }
    }
    @media (min-width: $w-brkpnt-sm) {
      padding-right: 32px;
      padding-left: 32px;
      border-top: none;
    }
  }
  &--categories {
    overflow: hidden;
    padding: 16px;
    @media (min-width: $w-brkpnt-sm) {
      display: flex;
      align-items: center;
    }
  }
  &--reviews {
    min-height: 23px;
    width: 75%;
    @media (min-width: $w-brkpnt-sm) {
      width: 100%;
    }
  }
  &--actions {
    padding: 16px;
    position: absolute;
    right: 0;
    bottom: 0;
    @media (min-width: $w-brkpnt-sm) {
      padding-right: 32px;
      padding-left: 32px;
    }
  }
  &--site {
    border-color: transparent;
  }
  &--slider {
    &-arrow {
      opacity: 0;
    }
    &:hover {
      .tile-team--slider-arrow {
        opacity: 1;
      }
    }
  }
  &--slide {
    padding-bottom: 67%;
    @media (min-width: $w-brkpnt-md) {
      padding-bottom: 0;
      width: 242px;
      height: 165px;
    }
  }
}

.tile-post {
  @include trn-03;
  position: relative;
  &--simple {
    &-inner {
      min-height: 80px;
      @media (min-width: $w-brkpnt-sm) {
        padding-left: 1.75rem;
      }
    }
  }
  &--link {
    @include absolute-parent-size;
    z-index: 1;
  }
  &--linkbar {
    @include trn-03;
    min-height: $input-height-lg;
    background-color: $cl-surface;
  }
  &--thumb {
    background-color: $cl-surface;
    overflow: hidden;
    position: relative;
    display: block;
  }
  &--title {
    &:hover {
      text-decoration: underline;
    }
  }
  &--info {
    flex: 1;
    &-inner {
      width: 100%;
    }
  }
  &--iconthumb {
    width: 88px;
  }
  &--external {
    position: relative;
    &:before {
      @include trn-03;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      background-color: $cl-outline-light;
      z-index: -1;
    }
    &:hover {
      .tile-post--linkbar {
        background-color: $cl-outline-light;
      }
      &:before {
        z-index: 1;
        opacity: 1;
      }
    }
  }
  &--head-link {
    display: inline-block;
    vertical-align: baseline;
    span {
      @include trn-03;
      border-bottom: 1px solid currentColor;
    }
    &:hover {
      span {
        border-color: transparent;
      }
    }
    img {
      width: 1em;
      height: 1em;
      border: 1px solid $cl-outline;
    }
  }
  &--data {
    padding-top: 0.125rem;
  }
  &--foot-inner {
    @media (min-width: $w-brkpnt-sm) {
      padding-left: 2.25rem;
      padding-right: 2.25rem;
    }
  }
  &.mod-hover {
    &:hover {
      background-color: $cl-outline-light;
    }
  }
}

.team-logo {
  width: 96px;
  height: 96px;
  object-fit: cover;
  border: 1px solid $cl-outline;
  border-radius: 100%;
  background-color: $cl-light;
}

.team-vote {
  @include trn-03;
  @include absolute-parent-size;
  @include flex-align-justify-center;
  @include relative-index-10;
  border: none;
  z-index: 30;
}

.img-thumb-1 {
  padding-bottom: 58%;
}

.img-thumb-2 {
  padding-bottom: 66%;
}

.img-thumb-3 {
  padding-bottom: 62%;
}

.feed-loader-wrap {
  @media (min-width: $w-brkpnt-sm) {
    &:not(:empty) {
      border-top: 1px solid $cl-outline;
    }
  }
}

.feed-loader {
  position: relative;
  background-color: $cl-acc-3;
  &--link {
    @include absolute-parent-size;
    z-index: 11;
  }
}

.tile-review {
  &--head {
    display: flex;
    flex-wrap: wrap;
  }
  &--action {
    flex: 0 0 88px;
    max-width: 88px;
  }
  &--author {
    flex: 0 0 calc(100% - 88px);
    max-width: calc(100% - 88px);
  }
  &--pic {
    flex: 0 0 56px;
    max-width: 56px;
  }
  &--title {
    flex: 0 0 calc(100% - 56px);
    max-width: calc(100% - 56px);
  }
}

.tile-review {
  &--collapser {
    @include trn-03;
    @include btn-reset;
    @include btn-align;
    @include txt-body-1;
    color: $cl-acc-1;
    text-decoration: underline;
    justify-content: flex-start;
    &:hover {
      text-decoration: none;
    }
  }
}

.item-review {
  &--pic {
    flex: 0 0 40px;
    max-width: 40px;
    height: 40px;
  }
  &--rating {
    flex: 0 0 70px;
    max-width: 70px;
  }
  &--team {
    flex: 0 0 calc(100% - 40px);
    max-width: calc(100% - 40px);
    &-row {
      flex: 0 0 calc(100% - 70px);
      max-width: calc(100% - 70px);
    }
    &-name {
      @include trn-03;
      color: $cl-main;
      &:hover {
        text-decoration: underline;
      }
    }
    &-author {
      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.tile-search {
  @include trn-03;
  border-radius: $bord-rad-2;
  &:hover {
    background-color: $cl-outline-light;
  }
  &--img {
    width: 48px;
  }
  &--content {
    width: calc(100% - 48px);
  }
}

.card-agency {
  background-color: $cl-light;
  box-shadow: $shadow-3;
  border-radius: $bord-rad-2;
  position: relative;
  &--link {
    @include absolute-parent-size;
  }
  &--img {
    .team-logo {
      width: 48px;
      height: 48px;
    }
  }
  &--title {
    @include txt-ttl-3;
  }
  &--drop {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 300;
    transition: all 0.2s ease-in;
    display: none;
    visibility: hidden;
    opacity: 0;
    min-width: 264px;
    @media (min-width: $w-brkpnt-sm) {
      display: block;
    }
  }
  &--drop-trigger {
    position: relative;
    &:hover {
      @media (min-width: $w-brkpnt-sm) {
        .card-agency--drop {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
  .icon-list--item-icon {
    width: 32px;
  }
  .icon-list--item-cont {
    @include txt-body-2;
    flex: 1;
  }
}