.cl-inherit {
	color: inherit;
}

.cl-light {
	color: $cl-light;
}
 
.cl-light-muted {
	color: $cl-light-muted;
}

.cl-main {
	color: $cl-main;
}

.cl-main-muted {
	color: $cl-main-muted;
}

.cl-muted {
	color: $cl-main-opaque;
}

.cl-error {
	color: $cl-error;
}

.cl-acc-1 {
	color: $cl-acc-1;
}

.cl-acc-2 {
	color: $cl-acc-2;
}

.cl-acc-4 {
	color: $cl-acc-4;
}

.cl-gradient-1 {
	color: $cl-gradient-1;
}

.bg-light {
	background-color: $cl-light;
}

.bg-surface {
	background-color: $cl-surface;
}

.bg-light-muted {
	background-color: $cl-light-muted;
}

.bg-acc-3 {
	background-color: $cl-acc-3;
}

.bg-gradient-1 {
	background: linear-gradient(94.67deg, #9D80B8 0%, #C77979 100%), #FFFFFF;
}

.cl-projects {
	color: $cl-projects;
}

.cl-questions {
	color: $cl-questions;
}

.cl-podcasts {
	color: $cl-podcasts;
}

.cl-ama {
	color: $cl-ama;
}

.cl-events {
	color: $cl-events;
}

.cl-materials {
	color: $cl-materials;
}

.cl-facebook {
	color: #3B5998;
}

.cl-linkedin {
	color: #0E76A8;
}