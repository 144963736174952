.side-nav {
  &.mod-leading {
    li {
      &.current-menu-item,
      &.current-menu-parent,
      &.current-page-ancestor {
        a {
          color: $cl-acc-1;
        }
        &.active-gradient-1 {
          a {
            color: #B87C91;
          }
        }
        &.active-acc-1 {
          a {
            color: $cl-acc-1;
          }
        }
        &.active-projects {
          a {
            color: $cl-projects;
          }
        }
        &.active-questions {
          a {
            color: $cl-questions;
          }
        }
        &.active-podcasts {
          a {
            color: $cl-podcasts;
          }
        }
        &.active-ama {
          a {
            color: $cl-ama;
          }
        }
        &.active-materials {
          a {
            color: $cl-materials;
          }
        }
      }
    }
    a {
      @include txt-ttl-4;
      &.active,
      &:active,
      &:hover {
        background-color: $cl-outline-light;
      }
    }
  }
  &.mod-secondary {
    a {
      @include txt-body-2;
      padding: 16px;
      margin-bottom: 8px;
      border-radius: $bord-rad-2;
      &.active,
      &:active,
      &:hover {
        background-color: $cl-surface-hover;
      }
    }
  }
  &.mod-searches {
    a {
      width: 100%;
      svg {
        flex: 0 0 24px;
        max-width: 24px;
      }
      .width-rest {
        flex: 0 0 calc(100% - 24px);
        max-width: calc(100% - 24px);
        padding-left: 16px;
      }
      &.active,
      &:active,
      &:hover {
        color: $cl-acc-1 !important;
      }
    }
  }
  a {
    @include flex-align-center;
    color: $cl-main;
    padding: 16px;
    border-radius: $bord-rad-2;
  }
}

.side-nav--link {
  @include txt-ttl-4;
  @include flex-align-center;
  color: $cl-main;
  padding: 8px 16px;
  border-radius: 24px;
  &.active,
  &:active,
  &:hover {
    background-color: $cl-surface-hover;
    color: $cl-acc-1;
  }
}


// body.archive,
body.single-post {
  .side-nav {
    &.mod-leading {
      li.current_page_parent {
        a {
          color: $cl-acc-1;
        }
      }
    }
  }
}

body.post-type-archive-posts {
  .side-nav {
    &.mod-leading {
      li.menu-item-object-page {
        a {
          background-color: transparent;
          color: $cl-main;
          &:active,
          &:hover {
            background-color: $cl-surface-hover;
            color: $cl-acc-1;
          }
        }
      }
    }
  }
}

.tl-nav {
  @include list-reset;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid transparent;
  .tl-nav--item {
    flex: 1 1 0;
    text-align: center;
  }
  &--link {
    display: block;
    padding: 12px 0;
    border-bottom: 3px solid transparent;
    margin-bottom: -1px;
    &.active {
      border-color: currentColor;
    }
  }
  &.mod-dark {
    border-color: $cl-outline;
    .tl-nav--link {
      color: $cl-main-muted;
      &.active {
        color: $cl-main;
      }
    }
  }
  &.mod-light {
    border-color: $cl-light;
    .tl-nav--link {
      color: $cl-light;
    }
  }
}