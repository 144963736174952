// @font-face {
//   font-family: 'Neue Montreal';
//   src: url('../fonts/NeueMontreal-Medium.woff2') format('woff2'),
//       url('../fonts/NeueMontreal-Medium.woff') format('woff'),
//       url('../fonts/NeueMontreal-Medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;  
// }

// @font-face {
//   font-family: 'Neue Montreal';
//   src: url('../fonts/NeueMontreal-Regular.woff2') format('woff2'),
//       url('../fonts/NeueMontreal-Regular.woff') format('woff'),
//       url('../fonts/NeueMontreal-Regular.ttf') format('truetype');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;   
// }

@font-face {
  font-family: 'Neue Montreal';
  font-weight: 500;
  font-style: normal;
  font-display: swap;
  src: local('Neue Montreal'),
    url('../fonts/NeueMontreal-Medium.woff2') format('woff2'),
    url('../fonts/NeueMontreal-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Neue Montreal';
  font-weight: normal;
  font-style: normal;
  font-display: swap;
  src: local('Neue Montreal'),
    url('../fonts/NeueMontreal-Regular.woff2') format('woff2'),
    url('../fonts/NeueMontreal-Regular.woff') format('woff');
}