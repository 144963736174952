.ttl-1 {
  @include txt-ttl-1;
}

.ttl-2 {
  @include txt-ttl-2;
}

.ttl-3 {
  @include txt-ttl-3;
}

.ttl-4 {
  @include txt-ttl-4;
}

.txt-body-1 {
  @include txt-body-1;
}

.txt-body-2 {
  @include txt-body-2;
}

.txt-subttl-1 {
  @include txt-subttl-1;
}

.txt-subttl-2 {
  @include txt-subttl-2;
}

.txt-caption {
  @include txt-caption;
}

.txt-btn {
  @include txt-btn;
}

.fw-400 {
  font-weight: 400 !important;
}