.remodal[data-remodal-id="report-post"] {
  padding: 24px;
  border-radius: $bord-rad-1;
  .remodal-close {
    left: auto;
    right: 0;
  }
  #report-post-modal-title {
    @include txt-ttl-2;
  }
  #report-post-modal-desc {
    @include txt-ttl-3;
  }
  .report-post-form {
    p {
      @include txt-body-2;
    }
    input,
    textarea {
      @include txt-fld;
      @include txt-fld-def;
      @include txt-fld-size-xl;
      &:read-only {
        background-color: $cl-surface !important;
        color: $cl-main-opaque;
      }
    }
  }
  #report-post-buttons {
    .remodal-cancel {
      background-color: $cl-error !important;
      color: $cl-light !important;
    }
    .remodal-cancel,
    .remodal-confirm {
      @include trn-03;
      @include btn-reset;
      @include btn-align;
      @include btn-corners;
      @include txt-btn;
      @include btn-def;
      @include btn-lg;
      margin-left: 16px;
      margin-right: 16px;
    }
    .remodal-confirm {
      @include btn-acc-2;
    }
  }
}

.tl-main {
  .report-post-link {
    background-image: none;
    margin: 0;
  }
}