.tl-pagination {
  display: inline-flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $cl-main;
  > a,
  > span {
    @include txt-body-2;
    color: $cl-main;
    text-decoration: none;
    display: inline-flex;
    padding: 1.5rem;
    border-bottom: 3px solid transparent;
    &.current {
      border-color: $cl-main;
    }
  }
  > a {
    &:hover {
      border-color: $cl-main;
    }
  }
}