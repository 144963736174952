.team {
  &-heading {
    padding-right: 106px;
  }
  &-topspacing {
    padding-top: 96px;
  }
  &-gallery {
    position: relative;
    &--dots {
      top: 100%;
      left: 50%;
      width: auto;
      z-index: 20;
      transform: translateX(-50%);
      span {
        margin-left: 4px;
        margin-right: 4px;
        opacity: 1;
        background-color: $cl-main-opaque;
        &:focus {
          outline: none;
        }
        &.active {
          background-color: $cl-main;
        }
      }
    }
  }
  &-overview {
    position: relative;
    margin-top: -48px;
    padding-bottom: 24px;
    &--header {
      @include relative-index-10;
    }
  }
  &-voting {
    width: 90px;
    height: $input-height-xl;
    margin-top: 12px;
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 50;
  }
  &-range {
    width: 250px;
    max-width: 100%;
  }
  &-description {
    .likebtn_container {
      display: none;
    }
  }
  &-footer {
    p {
      margin-top: 1em;
    }
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.single-team {
  &--head {
    position: relative;
  }
  &--like {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
    &:hover {
      z-index: 5;
    }
    @media (min-width: $w-brkpnt-sm) {
      right: auto;
      left: 0;
      top: 6rem;
      margin-top: -1rem;
    }
    @media (min-width: $w-brkpnt-md) {
      top: 9.6rem;
      margin-top: -1.5rem;
    }
  }
  &--statuses {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  &--text {
    @media (max-width: 767px) {
      padding-left: 0;
      width: 100%;
    }
    @media (min-width: $w-brkpnt-sm) {
      padding-right: 6rem;
      min-height: 3rem;
    }
    @media (min-width: $w-brkpnt-md) {
      padding-right: 10rem;
      min-height: 5rem;
    }
  }
  &--cell {
    border-top: 1px solid $cl-outline-light;
  }
}