// Colors
$cl-light: #fff;
$cl-light-muted: rgba(#fff, 0.74);
$cl-light-opaque: rgba(#fff, 0.38);
$cl-light-opaque-extra: rgba(#fff, 0.08);
$cl-bar: #202041;
$cl-main: rgba(#000000, 0.87);
$cl-main-muted: rgba(#000000, 0.6);
$cl-main-opaque: rgba(#000000, 0.38);
$cl-bg: #F3F4F7;
$cl-bg-1: #F5F5F5;
$cl-surface: rgba(#212121, 0.08);
$cl-surface-hover: rgba(#212121, 0.12);
$cl-outline: rgba(#000, 0.12);
$cl-outline-light: rgba(#000, 0.04);
$cl-error: #B00020;
$cl-acc-1: #5972F7;
$cl-acc-1-hover: #4468E0; 
$cl-acc-1-muted: rgba(#5972F7, 0.24);
$cl-acc-2: #4FC369;
$cl-acc-3: #F0F3FD;
$cl-acc-4: #EB5757;
$cl-acc-5: #2AB750;
$cl-acc-5-muted: #E6F6E9;
$cl-acc-6: #F44514;
$cl-gradient-1: linear-gradient(100.73deg, #9D80B8 0%, #C77979 100%), #FFFFFF;

$cl-projects: #5972F7;
$cl-questions: #E789C9;
$cl-podcasts: #F2994A;
$cl-ama: #2D9CDB;
$cl-events: #2D9CDB;
$cl-materials: #EB5757;

$shadow-1: 0px 11px 15px rgba(0, 0, 0, 0.2), 0px 9px 46px rgba(0, 0, 0, 0.12), 0px 24px 38px rgba(0, 0, 0, 0.14);
$shadow-2: 0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 1px rgba(0, 0, 0, 0.14);
$shadow-3: 0px 3px 14px rgba(0, 0, 0, 0.12);
$shadow-4: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 6px 10px rgba(0, 0, 0, 0.14);
$shadow-5: 0px 2px 8px rgba(0, 0, 0, 0.15);
$shadow-6: 0px 1px 1px rgba(0, 0, 0, 0.14);

// Fonts
$font-title: 'Neue Montreal', Arial, sans-serif;
$font-main: 'Neue Montreal', Arial, sans-serif;

// Misc
$input-height-sm: 32px;
$input-height-smd: 36px;
$input-height-md: 40px;
$input-height-lg: 48px;
$input-height-xl: 56px;

$header-height-mob: 58px;
$header-height-desk: 72px;
$footer-height: 32px;
$bord-rad-1: 4px;
$bord-rad-2: 8px;
$bord-rad-3: 16px;

$w-side-col-left: 272px;
$w-side-center: 624px;
$w-side-right: 384px;

$w-brkpnt-sm-down: 767px;
$w-brkpnt-sm: 768px;
$w-brkpnt-md-down: 895px;
$w-brkpnt-md: 896px;
$w-brkpnt-lg-down: 1279px;
$w-brkpnt-lg: 1280px;

// Fonts
@mixin base-font {
  font-family: $font-main;
  font-weight: 400;
  letter-spacing: 0.050em;
}

@mixin title-font {
  font-family: $font-title;
  font-weight: 500;
  letter-spacing: 0.050em;
}

@mixin txt-body-1 {
  @include base-font;
  font-size: 16px;
  line-height: 1.5;
}

@mixin txt-body-2 {
  @include base-font;
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: 0.063em;
}

@mixin txt-ttl-1 {
  @include title-font;
  font-size: 32px;
  line-height: 1.17;
  letter-spacing: 0.013em;
  @media (min-width: $w-brkpnt-md) {
    font-size: 48px;
  }
}

@mixin txt-ttl-2 {
  @include title-font;
  font-size: 24px; 
  line-height: 1.17;
  letter-spacing: 0.025em;
  @media (min-width: $w-brkpnt-md) {
    font-size: 32px; 
  }
}

@mixin txt-ttl-3 {
  @include title-font;
  font-size: 20px;
  line-height: 1.2;
  letter-spacing: 0.037em;
}

@mixin txt-ttl-4 {
  @include title-font;
  font-size: 24px;
  line-height: 1.33;
  letter-spacing: 0.037em;
}

@mixin txt-subttl-1 {
  @include base-font;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 500;
  letter-spacing: 0.037em;
}

@mixin txt-subttl-2 {
  font-family: $font-main;
  font-size: 14px;
  line-height: 1.71;
  font-weight: 500;
  letter-spacing: 0.037em;
}

@mixin txt-caption {
  font-family: $font-main;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.050em;
}

@mixin txt-btn {
  font-family: $font-main;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.050em;
}

// Inputs
@mixin txt-fld {
  -webkit-appearance: none;
  border-radius: 0;
  display: block;
  width: 100%;
  border: 1px solid transparent;
  &:focus {
    outline: none;
  }
  &::-webkit-input-placeholder {
    color: $cl-main-opaque;
  }
}

@mixin txt-fld-sec {
  background-color: $cl-surface;
  &::-webkit-input-placeholder {
    color: $cl-main-opaque;
  }
}

@mixin txt-fld-def {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $cl-outline;
  &::-webkit-input-placeholder {
    color: $cl-main-opaque;
  }
  &:focus {
    border-color: transparent;
    box-shadow: inset 0 -2px 0 0 $cl-acc-1;
  }
}

@mixin txt-fld-size-md {
  @include txt-subttl-1;
  padding: 8px 0;
  min-height: $input-height-md;
}

@mixin txt-fld-size-xl {
  @include txt-subttl-1;
  padding: 15px 0;
  min-height: $input-height-xl;
}

// Common
@mixin trn-03 {
  transition: all 0.3s ease;
}

@mixin bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin bg-contain {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

@mixin square-shadow-image {
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@mixin list-reset {
  padding: 0;
	margin: 0;
	list-style: none;
}

@mixin txt-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin tl-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  line-height: inherit;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

@mixin tl-icon-sel {
  content: '';
  margin-right: 0.5em;
  width: 1.5em;
  height: 1.5em;
  transform: translateY(-0.125em);
}

@mixin relative-index-10 {
	position: relative;
	z-index: 10;
}

@mixin relative-index-20 {
	position: relative;
	z-index: 20;
}

@mixin absolute-centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin absolute-parent-size {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

@mixin flex-align-justify-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

@mixin flex-align-center {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@mixin flex-justify-center {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@mixin backdrop {
  display: none;
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: transparent;
}

// Buttons
@mixin btn-reset {
  -webkit-appearance: none;
  text-decoration: none;
  user-select: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  &:hover {
    text-decoration: none;
  }
}

@mixin btn-align {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  vertical-align: middle;
}

@mixin btn-corners {
  border-radius: $bord-rad-2;
}

@mixin btn-def {
  padding: 5px 12px;
  min-height: $input-height-smd;
  &.btn-cornered {
    border-radius: calc(#{$input-height-smd} / 2);
  }
}

@mixin btn-main {
  color: $cl-light;
  background-color: $cl-acc-1;
}

@mixin btn-acc-1 {
  color: $cl-light;
  background-color: $cl-acc-1;
  &:hover, 
  &:active {
    background-color: $cl-acc-1-hover;
  }
}

@mixin btn-danger {
  color: $cl-light;
  background-color: $cl-error;
  &:hover, 
  &:active {
    opacity: 0.9;
  }
}

@mixin btn-acc-2 {
  color: $cl-light;
  background-color: $cl-acc-2;
}

@mixin btn-acc-6 {
  color: $cl-light;
  background-color: $cl-acc-6;
}

@mixin btn-sec {
  color: $cl-main;
  background-color: $cl-surface;
  &:hover,
  &:active {
    background-color: $cl-surface-hover;
  }
}

@mixin btn-flat {
  color: $cl-main-muted;
  background-color: transparent;
  &.cl-acc-1 {
    color: $cl-acc-1;
  }
  &:hover,
  &:active {
    background-color: $cl-outline-light;
    color: $cl-main;
  }
}

@mixin btn-outline {
  color: $cl-main;
  background-color: $cl-light;
  border: 1px solid $cl-outline;
  box-shadow: $shadow-6;
  &:hover,
  &:active {
    color: $cl-main;
    background-color: $cl-surface;
  }
}

@mixin btn-sm {
  @include txt-body-2;
  text-transform: unset;
  min-height: $input-height-sm;
  min-width: $input-height-sm;
  padding: 4px 12px;
  &.btn-cornered {
    border-radius: calc(#{$input-height-sm} / 2);
  }
}

@mixin btn-md {
  min-height: $input-height-md;
  min-width: $input-height-md;
  padding: 5px 8px;
  &.btn-cornered {
    border-radius: calc(#{$input-height-md} / 2);
  }
}

@mixin btn-lg {
  min-height: $input-height-lg;
  min-width: $input-height-lg;
  padding: 16px 24px;
  &.btn-cornered {
    border-radius: calc(#{$input-height-lg} / 2);
  }
}

@mixin btn-xl {
  min-height: $input-height-xl;
  min-width: $input-height-xl;
  padding: 18px 36px;
  &.btn-cornered {
    border-radius: calc(#{$input-height-xl / 2});
  }
}

@mixin btn-ellipsis {
  @include txt-ellipsis;
  display: inline-block !important;
  text-align: left !important;
  justify-content: flex-start !important;
  max-width: 100%;
}

// Misc
@mixin content-media {
  max-width: none;
  width: calc(100% + 32px);
  margin-left: -16px;
  @media (min-width: $w-brkpnt-sm) {
    width: calc(100% + 64px);
    margin-left: -32px;
  }
}

@mixin embed-video {
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

// Alerts
@mixin tl-alert {
  @include txt-body-2;
  box-shadow: $shadow-4;
  padding: 14px 16px;
  border-radius: $bord-rad-1;
}

@mixin tl-alert-def {
  color: $cl-light;
  background-color: $cl-main;
}

@mixin tl-alert-err {
  color: $cl-light;
  background-color: $cl-error;
}

@mixin tl-alert-succ {
  color: $cl-light;
  background-color: $cl-acc-2;
}