.teamslist {
  &-title {
    sup {
      @include txt-body-2;
      display: inline-block;
      transform: translateY(-0.75em);
    }
  }
  &-filters {
    &--row {
      overflow-x: auto;
    }
  }
}