.tl-slider {
  position: relative;
  &--arrow {
    @include trn-03;
    @include flex-align-justify-center;
    position: absolute;
    bottom: 50%;
    z-index: 2;
    margin-bottom: -18px;
    padding: 0.3rem;
    width: 36px;
    height: 36px;
    background-color: $cl-light-muted;
    color: $cl-main;
    border-radius: 100%;
    cursor: pointer;
    outline: none;
    &.prev {
      left: 12px;
    }
    &.next {
      right: 12px;
    }
    &:hover {
      box-shadow: $shadow-2;
      background-color: $cl-light;
    }
  }
  .svg-icon {
    height: 12px;
    width: auto;
  }
}

.tile-team {
  &:hover {
    .tl-slider--arrow {
      background-color: $cl-light;
    }
  }
}