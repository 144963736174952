.tl-label {
  @include txt-subttl-2;
  cursor: pointer;
}

select,
.tl-input {
  @include txt-fld;
  @include txt-fld-def;
  @include txt-fld-size-md;
}

.tl-radiobtn-sec {
  label {
    @include trn-03;
    @include btn-reset;
    @include btn-align;
    @include btn-corners;
    @include btn-sec;
  }
  &.mod-sm {
    label {
      @include btn-sm;
    }
  }
}

.tl-radiobtn-flat {
  label {
    @include trn-03;
    @include btn-reset;
    @include btn-align;
    @include btn-corners;
    @include btn-flat;
  }
  &.mod-sm {
    label {
      @include btn-sm;
    }
  }
}

.tl-radiobtn-sec,
.tl-radiobtn-flat {
  input:checked {
    + label {
      color: $cl-acc-1;
    }
  }
}

.tl-radio-text,
.tl-radiobtn-sec,
.tl-radiobtn-flat,
.tl-radio,
.tl-checkbox {
  input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    cursor: pointer;
  }
  label {
    margin-bottom: 0;
    cursor: pointer;
  }
}

.tl-radio,
.tl-checkbox {
  position: relative;
  input {
    @include trn-03;
    -webkit-appearance: none;
    opacity: 1;
    outline: none;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    z-index: -1;
    left: -8px;
    top: -8px;
    &:hover,
    &:active {
      background-color: $cl-outline;
    }
  }
  label {
    @include trn-03;
    position: relative;
    padding-left: 32px;
    &:before,
    &:after {
      content: '';
      @include trn-03;
      position: absolute;
      display: inline-block;
    }
  }
  input:checked {
    + label {
      &:before {
        background-color: currentColor;
      }
      &:after {
        opacity: 1;
      }
    }
  }
}

.tl-radio,
.tl-checkbox {
  label {
    &:before {
      left: 0;
      top: 0;
      border: 2px solid currentColor;
      border-radius: $bord-rad-1;
      background-color: transparent;
      width: 20px;
      height: 20px;
      transition: background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

.tl-radio {
  label {
    &:after {
      left: 5px;
      top: 5px;
      width: 10px;
      height: 10px;
      border-radius: 2px;
      background-color: $cl-light;
      opacity: 0;
      transition: background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
  &.mod-btn-sec {
    label {
      padding-left: 0;
      &:before,
      &:after {
        display: none;
      }
    }
  }
}

.tl-checkbox {
  label {
    &:after {
      left: 5px;
      top: 6px;
      width: 10px;
      height: 6px;
      border-left: 2px solid $cl-light;
      border-bottom: 2px solid $cl-light;
      transform: rotate(-45deg);
      opacity: 0;
      transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1);
    }
  }
}

textarea {
  height: auto;
  
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.tl-input-group {
  border-radius: $bord-rad-1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  &-prepend,
  &-append {
    flex: 0 0 $input-height-xl;
    padding-left: 0;
  }
  &-prepend {
    border-radius: $bord-rad-1 0 0 $bord-rad-1;
    + .tl-input-group-input {
      border-radius: 0 $bord-rad-1 $bord-rad-1 0;
      border-left: none;
    }
  }
  &-append {
    border-radius: 0 $bord-rad-1 $bord-rad-1 0;
  }
  &-input {
    border-radius: $bord-rad-1 0 0 $bord-rad-1;
    flex: 1;
  }
}

.input-group-style-1 {
  position: relative;
  .tl-input-btn {
    @include relative-index-10;
    color: $cl-main-muted;
  }

  .tl-input-btn,
  .tl-input {
    border-radius: 0;
    background-color: transparent;
  }

  .tl-input {
    min-height: $input-height-xl;
    color: $cl-main;
    &::-webkit-input-placeholder {
      color: $cl-main-opaque;
    }
  }
  .tl-input-group {
    &.drop-open {
      background-color: $cl-light;
      box-shadow: $shadow-3;
    }
  }
}

.non-touch-device {
  .input-group-style-1 {
    .tl-input {
      margin-left: -$input-height-xl;
      padding-left: $input-height-xl;
    }
  }
}

.input-group-style-2 {
  .tl-input-btn {
    border-color: $cl-outline;
    color: $cl-main-muted;
  }

  .tl-input-btn,
  .tl-input {
    background-color: $cl-light;
  }

  .tl-input {
    color: $cl-main;
    &::-webkit-input-placeholder {
      color: $cl-main-opaque;
    }
  }
}

.rslider {
  width: 100%;
  height: 2px;
  background-color: $cl-acc-1-muted;
  display: flex;
  &-fill {
    background-color: $cl-acc-1;
  }
}